import React from 'react';
import './ChatTile.css';
import { ChatWithMemberArray } from '../../models/ChatSchema';
import { roundNumber } from '../../utils';
import { Link } from 'react-router-dom';
import { chatIds, chatNames } from '../../global';


type ChatTileProps = {
    chat: ChatWithMemberArray;
}

export default class ChatTile extends React.Component<ChatTileProps, {}> {
    render() {
        const chatLink = `/chat/${this.props.chat.chat_id}`;

        let chatImage = this.props.chat.image_url;
        if(!chatImage) {
            chatImage = "https://scontent-den4-1.xx.fbcdn.net/v/t31.0-8/p960x960/19983764_10154736207952393_" +
                "8612927327416028525_o.png?_nc_cat=110&_nc_sid=85a577&_nc_ohc=okvQQhCESOoAX_tFfwj&_nc_ht=scontent-den4-1.xx&oh=" + 
                "5dc5b544af251897b8286e2fd9a33065&oe=5EE07DDD";
        }


        return (
            <div className="chatTile">
                <Link to={chatLink}>
                    <div className="chatImage" style={{backgroundImage: `url(${chatImage})`}}></div>
                </Link>

                <Link to={chatLink} className="chatTitle">{chatNames[chatIds.indexOf(this.props.chat.chat_id)]}</Link>
                
                <div className="chatInfoWrapper">
                    <div className="chatInfoWrapperInner">
                        <div className="chatInfoWrapperLeft">
                            <p>Members:</p>
                            <p>Messages:</p>
                            <p>Likes:</p>
                            <p>Likes/msg:</p>
                        </div>
                        <div className="chatInfoWrapperRight">
                            <p>{this.props.chat.members.length}</p>
                            <p>{this.props.chat.totalNumMessages}</p>
                            <p>{this.props.chat.totalNumLikes}</p>
                            <p>{roundNumber(this.props.chat.totalNumLikes / this.props.chat.totalNumMessages)}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}