import React from 'react';
import './RankingsBar.css';


type RankingsBarProps = {
    text: string,
    width: number
}

export default class RankingsBar extends React.Component<RankingsBarProps, {}> {
    render() {
        return (
            <div className="rankingsBarDiv" style={{width:`${this.props.width}%`}}>{this.props.text}</div>
        );
    }
}