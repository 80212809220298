import React from 'react';
import './Footer.css';


export default function Footer() {
    return (
        <div id="footer">
            <p>Handcrafted by Kieran McWilliams and Daniel Noon, with ideas from Cohort 2019 and base inspiration from chatstats.co</p>
            <p>Built with TypeScript, Node, Express, MongoDB, React, and the GroupMe API</p>
        </div>
    );
}