import React from 'react';
import './RankingsName.css';
import { Link } from 'react-router-dom';


type RankingsNameProps = {
    name: string,
    userId?: string,
    chatId: string
}

export default class RankingsName extends React.Component<RankingsNameProps, {}> {
    render() {
        let userLink = "";
        if(this.props.userId) {
            userLink = `/user/${this.props.userId}/chat/${this.props.chatId}`;
        }

        return (
            <div className="rankingsNameDiv"><p><Link to={userLink} className={!this.props.userId ? "noLink" : ""}>
                {this.props.name}
            </Link></p></div>
        );
    }
}