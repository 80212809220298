import React, { ChangeEvent } from 'react';
import './RankingsWrapper.css';
import RankingsName from '../rankingsName/RankingsName';
import RankingsBar from '../rankingsBar/RankingsBar';
import { sortGenerator, roundNumber } from '../../utils';
import { fieldsToPrintType } from '../../global';
import { MemberWithFavoritesDict } from '../../models/MemberSchema';
import { Ranking } from '../../models/Ranking';


type RankingsWrapperProps = {
    rankings: Ranking[],
    chatId: string,
    isUserRankings?: boolean,
    rankingsSortedByPercent?: Ranking[],
    maxNumLikesPerMessage?: number
}
type RankingsWrapperState = {
    rankingsSwitchIsChecked: boolean,
}

export default class RankingsWrapper extends React.Component<RankingsWrapperProps, RankingsWrapperState> {
    constructor(props: RankingsWrapperProps){
        super(props);

        this.state = {
            rankingsSwitchIsChecked: false
        }

        this.updateRankingsSwitchIsChecked = this.updateRankingsSwitchIsChecked.bind(this);
    }

    updateRankingsSwitchIsChecked(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            rankingsSwitchIsChecked: event.target.checked
        })
    }


    render() {
        let rankingNamesMapFunction = (ranking: Ranking) => {
            if(ranking.value !== -1) {
                return <RankingsName name={ranking.title} userId={ranking.user_id} chatId={this.props.chatId}
                    key={(ranking.user_id ? ranking.user_id : ranking.title) + "name"} />;
            }
        };

        let rankingBarsMapFunction = (ranking: Ranking) => {
            if(ranking.value !== -1) {
                return <RankingsBar
                    text={
                        !this.state.rankingsSwitchIsChecked || !ranking.numSentMessages || !this.props.rankings[0].numSentMessages ?
                            (ranking.value === 0 ? "" : roundNumber(ranking.value) + "") :
                            roundNumber(roundNumber(ranking.value / ranking.numSentMessages) * 100) + "% of " + ranking.numSentMessages
                    }
                    width={
                        !this.state.rankingsSwitchIsChecked || !ranking.numSentMessages || !this.props.rankings[0].numSentMessages ?
                            roundNumber(ranking.value / (this.props.maxNumLikesPerMessage && this.props.maxNumLikesPerMessage > 0 ?
                                this.props.maxNumLikesPerMessage : this.props.rankings[0].value) * 100) :
                                roundNumber(ranking.value / ranking.numSentMessages) * 100
                    }
                    key={(ranking.user_id ? ranking.user_id : ranking.title) + "bar"} />;
            }
        };


        return (
            <div id="rankingsWrapper">
                <p id="rankingsHeader">{this.props.isUserRankings ? "Favorites" :
                    (this.props.maxNumLikesPerMessage && this.props.maxNumLikesPerMessage > 0 ? null : "Complete Rankings")}</p>
                
                {
                    this.props.isUserRankings ? 
                        <div id="rankingsSwitchWrapper">
                        <p id="rankingsSwitchHeaderLeft">By Number of Messages Liked</p>
                        <label className="rankingsSwitch">
                            <input className="rankingsSwitch-input" type="checkbox" onChange={this.updateRankingsSwitchIsChecked} />
                            <span className="rankingsSwitch-label" data-on="On" data-off="Off"></span> 
                            <span className="rankingsSwitch-handle"></span> 
                        </label>
                        <p id="rankingsSwitchHeaderRight">By % of Messages Liked</p>
                        </div>
                    : null
                }
    
                <div id="rankingsWrapperInner">
                    <div id="rankingsNames">
                        {
                            !this.state.rankingsSwitchIsChecked || !this.props.rankingsSortedByPercent ?
                                this.props.rankings.map(rankingNamesMapFunction) :
                                this.props.rankingsSortedByPercent.map(rankingNamesMapFunction)
                        }
                    </div>
                    <div id="rankingsBars">
                        {
                            !this.state.rankingsSwitchIsChecked || !this.props.rankingsSortedByPercent ?
                                this.props.rankings.map(rankingBarsMapFunction) :
                                this.props.rankingsSortedByPercent.map(rankingBarsMapFunction)
                        }
                    </div>
                </div>
            </div>
        );
    }
}