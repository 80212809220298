import React from 'react';
import './OverallStatisticWrapper.css';
import OverallStatistic from '../overallStatistic/OverallStatistic';
import { OverallStatisticType } from '../../models/OverallStatisticType';


type OverallStatisticWrapperProps = {
    statistics: OverallStatisticType[]
}

export default class OverallStatisticWrapper extends React.Component<OverallStatisticWrapperProps, {}> {
    render () {
        return (
            <div id="overallStatisticsWrapper" className={this.props.statistics.length === 4 ? "chat" : ""}>
                <div id="overallStatisticsWrapperInner">
                    {
                        this.props.statistics.map((statistic: OverallStatisticType) => {
                            return <OverallStatistic statistic={statistic} key={statistic.title} />;
                        })
                    }
                </div>
            </div>
        );
    }
}