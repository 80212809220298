import { serverURL } from "./global";

import { ChatWithMemberArray } from "./models/ChatSchema";
import { RuntimeStats } from "./models/RuntimeStats";


export const fetchDatabase = async () => {
    const localStorageKey = "databaseStringifiedResponse";

    await localStorage.removeItem(localStorageKey);

    const getFetchedResponse = () => {
        if(window.origin.includes("mega")) {
            return fetch(serverURL + "fetch-database-mega").then(res => res.json());
        } else {
            return fetch(serverURL + "fetch-database").then(res => res.json());
        }
    }

    const fetchedResponse = await getFetchedResponse();

    console.log(fetchedResponse);

    let stringifiedResponse;
    if(fetchedResponse) {
        stringifiedResponse = await JSON.stringify(fetchedResponse);
        await localStorage.setItem(localStorageKey, stringifiedResponse);
    }
    
    if(stringifiedResponse) {
        const response = await JSON.parse(stringifiedResponse);

        return response as {
            allChatsData: ChatWithMemberArray[],
            allRuntimeStats: RuntimeStats[]
        };
    }
}

export const refreshDatabase = () => {
    console.log("Refresh Database initiated!");

    fetch(serverURL + "refresh-database", {
        method: "POST"
    }).then(res => {
        console.log("Refresh database complete! Response: ", res);
    });
}