import io from 'socket.io-client';
import { fromEvent, Observable } from 'rxjs';
import { serverURL } from './global';
import { SocketEmit } from './models/SocketEmit';


export default class SocketService {
    private socket: SocketIOClient.Socket = {} as SocketIOClient.Socket;

    public init(): SocketService {
        this.socket = io(serverURL);
        return this;
    }

    public disconnect(): void {
        this.socket.disconnect();
    }

    public onDatabaseRefreshed(): Observable<SocketEmit> {
        return fromEvent(this.socket, 'databaseRefreshed');
    }

    public onDatabaseRefreshing(): Observable<SocketEmit> {
        return fromEvent(this.socket, 'databaseRefreshing');
    }
}