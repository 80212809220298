import React from 'react';
import { useParams } from 'react-router';

import './Chat.css';
import OverallStatisticsWrapper from '../../components/overallStatisticWrapper/OverallStatisticWrapper';
import Tabs from '../../components/tabsWrapper/TabsWrapper';
import MemberTileWrapper from '../../components/memberTileWrapper/MemberTileWrapper';
import RankingsWrapper from '../../components/rankingsWrapper/RankingsWrapper';
import { ChatWithMemberArray } from '../../models/ChatSchema';
import { fieldsToPrintType, timePeriodsType, chatNames, chatIds, fieldsToPrint, fieldsToPrintTabTitles, fieldsToPrintTitles } from '../../global';
import { RuntimeStats } from '../../models/RuntimeStats';
import Header from '../../components/header/Header';
import { OverallStatisticType } from '../../models/OverallStatisticType';
import { roundNumber, sortGenerator } from '../../utils';
import { DatabaseStore } from '../../databaseStore';
import LoadingScreen from '../../components/loadingScreen/LoadingScreen';
import { ServerResponse } from '../../models/ServerResponse';
import ErrorPage from '../../components/errorPage/ErrorPage';
import TabsWrapper from '../../components/tabsWrapper/TabsWrapper';
import { Ranking } from '../../models/Ranking';


type ChatProps = {
    match: {
        params: {
            chatId: string,
            stat: fieldsToPrintType,
            period: timePeriodsType
        }
    }
}
type ChatState = ServerResponse & {
    loaded: boolean
}

export default class Chat extends React.Component<ChatProps, ChatState> {
    constructor(props: ChatProps) {
        super(props);

        this.state = {
            allChatsData: [],
            allRuntimeStats: [],
            loaded: false
        }
    }

    componentDidMount() {
        this.getDatabaseStore();
    }

    componentWillUnmount() {
        DatabaseStore.unsubscribe("chat");
    }

    async getDatabaseStore() {
        this.setStateWithStore(await DatabaseStore.getStore());

        DatabaseStore.subscribe({
            id: "chat",
            callback: (store) => this.setStateWithStore(store)
        });
    }

    setStateWithStore(store: DatabaseStore) {
        const { allChatsData, allRuntimeStats } = store;
        this.setState({ allChatsData, allRuntimeStats, loaded: true });
    }

    
    render() {
        let { chatId, stat, period } = this.props.match.params;
        if(!stat) {
            stat = fieldsToPrint[0];
        }

        const chat = this.state.allChatsData.find(chat => chat.chat_id == chatId);
        if(!chat) {
            if(this.state.allChatsData.length === 0) {
                return (
                    <div>
                        <Header title={`Chat Statistics for ${chatNames[chatIds.indexOf(chatId)]}`}
                            allRuntimeStats={this.state.allRuntimeStats} />
    
                        <LoadingScreen />
                    </div>
                );
            } else {
                return <ErrorPage text={"Invalid Chat Id"} />
            }
        }

        let statistics: OverallStatisticType[] = [
            {
                title: "Members",
                num: chat.members.length
            }, {
                title: "Total Messages",
                num: chat.totalNumMessages
            }, {
                title: "Total Likes",
                num: chat.totalNumLikes
            }, {
                title: "Likes Per Message",
                num: roundNumber(chat.totalNumLikes / chat.totalNumMessages)
            }
        ];

        let rankings: Ranking[] = [];
        let maxNumLikesPerMessage: number = -1;

        if(stat !== "likeDistribution") {
            rankings = chat.members.sort(sortGenerator(stat)).slice(3).map(member => {
                return {
                    title: member.name,
                    value: stat !== "likeDistribution" ? member[stat] : -1,
                    user_id: member.user_id
                }
            });
        } else {
            const messageLikesKeysArray = Object.keys(chat.messageLikes);
            for(let i = 0; i < messageLikesKeysArray.length; i++) {
                const thisNumLikes = messageLikesKeysArray[i];
                const numMessagesWithThisNumLikes = chat.messageLikes[thisNumLikes];

                if(numMessagesWithThisNumLikes > maxNumLikesPerMessage) {
                    maxNumLikesPerMessage = numMessagesWithThisNumLikes;
                }

                rankings[parseInt(thisNumLikes)] = {
                    title: thisNumLikes,
                    value: numMessagesWithThisNumLikes
                };
            }

            for(let i = 0; i < rankings.length; i++) {
                if(!rankings[i]) {
                    rankings[i] = {
                        title: ""+i,
                        value: 0
                    }
                }
            }

            rankings = rankings.reverse();

            
            // rankings = Object.keys(chat.messageLikes).sort((a, b) => parseInt(b) - parseInt(a)).map(thisNumLikes => {
            //     const numMessagesWithThisNumLikes = chat.messageLikes[thisNumLikes];
            //     if(numMessagesWithThisNumLikes > maxNumLikesPerMessage) {
            //         maxNumLikesPerMessage = numMessagesWithThisNumLikes;
            //     }

            //     return {
            //         title: thisNumLikes,
            //         value: numMessagesWithThisNumLikes
            //     }
            // });
        }


        return (
            <div>
                <Header title={`Chat Statistics for ${chatNames[chatIds.indexOf(chatId)]}`}
                    allRuntimeStats={this.state.allRuntimeStats} />

                {
                    this.state.allChatsData.length === 0 ? <LoadingScreen /> : [
                        <OverallStatisticsWrapper key="0" statistics={statistics} />,

                        <TabsWrapper key="1" defaultIndex={fieldsToPrint.indexOf(stat)} chat={chat} />,

                        <h1 key="2" className="tabTitle">{fieldsToPrintTabTitles[fieldsToPrint.indexOf(stat)]}</h1>,
                        <p key="3" className="statTitle">{fieldsToPrintTitles[fieldsToPrint.indexOf(stat)]}</p>,
        
                        stat !== "likeDistribution" ? <MemberTileWrapper key="4" chat={chat} stat={stat} /> : null,
        
                        rankings ? <RankingsWrapper key="5" chatId={chatId} rankings={rankings}
                            maxNumLikesPerMessage={maxNumLikesPerMessage} /> : null
                    ]
                }
            </div>
        );
    }
}