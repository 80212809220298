import { ChatWithMemberArray } from "./models/ChatSchema";
import { RuntimeStats } from "./models/RuntimeStats";


export type DatabaseSubscriber = {
  callback: (store: DatabaseStore) => void,
  id: string
}

export class DatabaseStore {
  private static store: DatabaseStore;
  private static subscribers: DatabaseSubscriber[] = [];

  constructor(public allChatsData: ChatWithMemberArray[], public allRuntimeStats: RuntimeStats[]) {}

  public static subscribe(subscriber: DatabaseSubscriber) {
    this.subscribers.push(subscriber);
  }

  public static unsubscribe(id: string) {
    const subscriberIndex = this.subscribers.findIndex(s => s.id === id);
    this.subscribers.splice(subscriberIndex, 1);
  }

  public static update(store: DatabaseStore) {
    this.store = store;
    this.subscribers.forEach(subscriber => subscriber.callback(store));
  }

  public static async getStore() {
    return new Promise<DatabaseStore>((resolve, reject) => {
      if (this.store) {
        resolve(this.store);
      } else {
        const id = Math.random().toString();

        this.subscribe({
          id,
          callback: (store) => {
            this.unsubscribe(id);
            resolve(store);
          }
        })
      }
    })
  }
}