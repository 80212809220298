
export const serverURL = process.env.NODE_ENV === "production" ? "https://statsapi.kieranmcwilliams.com/" : "http://localhost:3001/";


//All Chats Combined = all, Cohort 2019 = 51117393, 2019 Info Chat = 53244531, 2019 Quotes = 55831109
//New Mega = 59991712, Old Mega = 51117502, Co-rona-hort = 59669294, CoHOT = 50340319, CoHottest = 58677097
export const chatIds = [ "all", "51117393", "53244531", "55831109", "59991712", "51117502", "59669294", "50340319", "58677097" ];

export type chatIdsType = typeof chatIds[number];

export const chatNames = [ "All Chats Combined", "Cohort 2019", "Cohort 2019 Info Chat", "Cohort 2019 Quotes",
    "New Megachat", "Old Megachat", "Co-rona-hort 2019-2020", "CoHOT 2018-2019", "CoHottest 2016 & 2019" ];


export const timePeriods = [ "allTime", "thisWeek", "thisMonth", "thisYear" ] as
    [ "allTime", "thisWeek", "thisMonth", "thisYear" ];

export type timePeriodsType = typeof timePeriods[number];


export const fieldsToPrintMember = [ "numMessagesLikedByOthers", "numSentMessages", "numLikedMessages", "avgLikesPerMessage",
    "numSelfLikes", "avgCharactersPerMessage", "timesMentionedOrMentioningOthers", "inDemandRatio" ] as
    [ "numMessagesLikedByOthers", "numSentMessages", "numLikedMessages", "avgLikesPerMessage",
    "numSelfLikes", "avgCharactersPerMessage", "timesMentionedOrMentioningOthers", "inDemandRatio" ];

export const fieldsToPrint = [ ...fieldsToPrintMember, "likeDistribution" ] as
    [ "numMessagesLikedByOthers", "numSentMessages", "numLikedMessages", "avgLikesPerMessage",
    "numSelfLikes", "avgCharactersPerMessage", "timesMentionedOrMentioningOthers", "inDemandRatio", "likeDistribution" ];

export type fieldsToPrintMemberType = typeof fieldsToPrintMember[number];
export type fieldsToPrintType = typeof fieldsToPrint[number];

export const fieldsToPrintTitles = [ "Total Likes Received from Others", "Total Messages Sent", "Total Likes Given to Others",
    "Average Likes Per Message", "Self Likes", "Average Characters Per Message", "Times Mentioned or Mentioning Others",
    "Ratio of Mentions Received to Mentions of Others", "Number of Messages Recieving Each Number of Likes" ];

export const fieldsToPrintTabTitles = [ "Most Popular", "Most Active", "Most Supportive", "Quality Content",
"Self-Promoting", "Most Verbose", "Most Social", "In Demand", "Like Distribution" ];