import React from 'react';

import './Home.css';
import ChatTileWrapper from '../../components/chatTileWrapper/ChatTileWrapper';
import { ChatWithMemberArray } from '../../models/ChatSchema';
import { RuntimeStats } from '../../models/RuntimeStats';
import Header from '../../components/header/Header';
import { DatabaseStore } from '../../databaseStore';
import LoadingScreen from '../../components/loadingScreen/LoadingScreen';
import { ServerResponse } from '../../models/ServerResponse';


type HomeProps = {
    allChatsData: ChatWithMemberArray[],
    allRuntimeStats: RuntimeStats[]
}
type HomeState = ServerResponse & {
    loaded: boolean
}

export default class Home extends React.Component<HomeProps, HomeState> {
    constructor(props: HomeProps) {
        super(props);

        this.state = {
            allChatsData: [],
            allRuntimeStats: [],
            loaded: false
        }
    }

    componentDidMount() {
        this.getDatabaseStore();
    }

    componentWillUnmount() {
        DatabaseStore.unsubscribe("home");
    }

    async getDatabaseStore() {
        this.setStateWithStore(await DatabaseStore.getStore());
        
        DatabaseStore.subscribe({
            id: "home",
            callback: (store) => this.setStateWithStore(store)
        });
    }

    setStateWithStore(store: DatabaseStore) {
        const { allChatsData, allRuntimeStats } = store;
        this.setState({ allChatsData, allRuntimeStats, loaded: true });
    }


    render() {
        return (
            <div>
                <Header title={"Welcome to Raikes GroupMe Statistics!"} allRuntimeStats={this.state.allRuntimeStats} />

                {
                    this.state.allChatsData.length === 0 ? <LoadingScreen /> : 
                        <ChatTileWrapper allChatsData={this.state.allChatsData} />
                }
            </div>
        );
    }
}