import React from 'react';
import './MemberTileWrapper.css';
import MemberTile from '../memberTile/MemberTile';
import { ChatWithMemberArray } from '../../models/ChatSchema';
import { sortGenerator } from '../../utils';
import { fieldsToPrintMemberType } from '../../global';
import { MemberWithFavoritesDict } from '../../models/MemberSchema';


type MemberTileWrapperProps = {
    chat: ChatWithMemberArray;
    stat: fieldsToPrintMemberType;
}

export default class MemberTileWrapper extends React.Component<MemberTileWrapperProps, {}> {
    render() {
        return (
            <div id="memberTileWrapper">
                <div id="memberTileWrapperInner">
                    {
                        this.props.chat.members.sort(sortGenerator(this.props.stat)).slice(0, 3).map(
                            (member: MemberWithFavoritesDict, index: number) => {
                                return <MemberTile member={member} stat={this.props.stat} chatId={this.props.chat.chat_id}
                                    index={index} key={member.user_id} />;
                            }
                        )
                    }
                </div>
            </div>
        );
    }
}