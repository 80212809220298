import { fieldsToPrintMemberType } from "./global";
import { MemberWithFavoritesDict } from "./models/MemberSchema";


export const roundNumber = (num: number, numDecimalPlaces?: number) => {
    if(!numDecimalPlaces) {
        numDecimalPlaces = 2;
    }

	return Number(Math.round(Number.parseFloat((num + Number.EPSILON) + 'e' + numDecimalPlaces)) + 'e-' + numDecimalPlaces);
}

export const sortGenerator = (field: fieldsToPrintMemberType) => {
	return function(a: MemberWithFavoritesDict, b: MemberWithFavoritesDict) {
		if(a[field] < b[field]) {
			return 1;
		} else if(a[field] > b[field]) {
			return -1;
		} else {
			return 0;
		}
	}
}