import React from 'react';
import './Header.css';
import ProgressBar from '../progressBar/ProgressBar';
import { RuntimeStats } from '../../models/RuntimeStats';
import { refreshDatabase } from '../../databaseAccessor';
import SocketService from '../../socketService';
import { SocketEmit } from '../../models/SocketEmit';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons'


type HeaderProps = {
    title: string,
    allRuntimeStats: RuntimeStats[]
}
type HeaderState = {
    currentlyRefreshing: boolean,
    currentlySignalingRefreshed: boolean,
    refreshingProgress: number,
    refreshingInterval: NodeJS.Timeout | null
}

export default class Header extends React.Component<HeaderProps, HeaderState> {
    private socketService = new SocketService();

    constructor(props: HeaderProps) {
        super(props);

        this.state = {
            currentlyRefreshing: false,
            currentlySignalingRefreshed: false,
            refreshingProgress: 0,
            refreshingInterval: null
        }
    }

    componentDidMount() {
        this.socketService.init();

        const onDatabaseRefreshedObservable = this.socketService.onDatabaseRefreshed();
        onDatabaseRefreshedObservable.subscribe((socketEmit: SocketEmit) => {
            if(!socketEmit.socket) {
                this.stopRefreshingInterval();
            }
            console.log(socketEmit);
        });

        const onDatabaseRefreshingObservable = this.socketService.onDatabaseRefreshing();
        onDatabaseRefreshingObservable.subscribe((socketEmit: SocketEmit) => {
            if(socketEmit.time !== undefined) {
                this.startRefreshingInterval(socketEmit.time);
            }
            console.log(socketEmit);
        });
    }

    componentWillUnmount() {
        this.socketService.disconnect();

        this.stopRefreshingInterval();
    }

    startRefreshingInterval(time: number) {
        this.stopRefreshingInterval();

        let header = this;

        this.setState({
            currentlyRefreshing: true,
            currentlySignalingRefreshed: false,
            refreshingProgress: time,
            refreshingInterval: setInterval(function() {
                header.setState({
                    refreshingProgress: header.state.refreshingProgress + 1
                });
            }, 1000)
        });
    }

    stopRefreshingInterval() {
        if(this.state.refreshingInterval) {
            clearInterval(this.state.refreshingInterval);

            let header = this;

            setTimeout(function() {
                header.setState({
                    currentlySignalingRefreshed: false
                })
            }, 5000);
        }

        this.setState({
            currentlyRefreshing: false,
            currentlySignalingRefreshed: true,
            refreshingProgress: 0
        });
    }


    formatDate(date: Date) {
        const hours = date.getHours();
        const mins = date.getMinutes();

        return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ` +
            `${hours > 12 ? hours - 12 : hours}:${mins < 10 ? "0" + mins : mins}${hours > 12 ? "pm" : "am"}`;
    }


    render() {
        const last5Refreshes = this.props.allRuntimeStats.filter(runtimeStat => runtimeStat.chat_id === "done").reverse().slice(0, 5);
        const avgRuntimeForPast5Refreshes = last5Refreshes.reduce((prev, current) => (prev + current.time), 0) / last5Refreshes.length;

        let lastUpdatedDate: Date | null = null;
        if(last5Refreshes[0]) {
            lastUpdatedDate = new Date(last5Refreshes[0].endDate);
        }

        const estTime = avgRuntimeForPast5Refreshes ? Math.round(avgRuntimeForPast5Refreshes) : " .. ";


        const getHomeLink = function(type: string) {
            return <Link id="homeLink" to="/" className={type}><FontAwesomeIcon id="homeIcon" icon={faHome} /></Link>;
        }

        const getRefreshButtonAndEstTime = function(state: HeaderState, type: string) {
            return [
                <button id="refreshButton" className={type} onClick={refreshDatabase}
                    disabled={state.currentlyRefreshing} key="1">Refresh Now</button>,

                <p id="refreshButtonEstimatedTime" className={type} key="2">
                    {
                        state.currentlySignalingRefreshed ? "Successfully Refreshed!" :
                            !state.currentlyRefreshing ? `Est. Time: ${estTime}s` :
                                `Refreshing Now: ${state.refreshingProgress}s/${estTime}s`
                    }
                </p>
            ];
        }
    

        return (
            <div id="header">
                <h1>{this.props.title}</h1>
                <p>Last Updated: {lastUpdatedDate ? this.formatDate(lastUpdatedDate) : " . . . "}</p>
                
                <ProgressBar width={Math.round(this.state.refreshingProgress / avgRuntimeForPast5Refreshes * 100)} />

                {getHomeLink("desktop")}
                
                {getRefreshButtonAndEstTime(this.state, "desktop")}
                
                <div id="homeLinkRefreshButtonDiv">
                    {getHomeLink("mobile")}

                    <div id="refreshButtonDiv">
                        {getRefreshButtonAndEstTime(this.state, "mobile")}
                    </div>
                </div>
            </div>
        );
    }
}