import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import './react-tabs.css';
import './TabsWrapper.css';

import { fieldsToPrint, fieldsToPrintTitles, fieldsToPrintTabTitles, chatIds, chatNames, fieldsToPrintType } from '../../global';
import MemberTileWrapper from '../memberTileWrapper/MemberTileWrapper';
import RankingsWrapper from '../rankingsWrapper/RankingsWrapper';
import { ChatWithMemberArray } from '../../models/ChatSchema';
import { sortGenerator, roundNumber } from '../../utils';
import { Ranking } from '../../models/Ranking';
import { OverallStatisticType } from '../../models/OverallStatisticType';
import OverallStatisticsWrapper from '../../components/overallStatisticWrapper/OverallStatisticWrapper';
import { Link, useHistory } from 'react-router-dom';


type SelectDropdownOption = {
    value: string,
    text: string
}

type TabsWrapperProps = {
    defaultIndex: number,
    chat?: ChatWithMemberArray,
    userId?: string,
    allChatsData?: ChatWithMemberArray[]
}

export default class TabsWrapper extends React.Component<TabsWrapperProps, {}> {
    constructor(props: TabsWrapperProps) {
        super(props);

        this.handleDropdownChange = this.handleDropdownChange.bind(this);
    }

    handleDropdownChange(event: any) {
        const selectedValue: fieldsToPrintType = event.target.value;

        let tabLinkElementId: string = "";
        if(this.props.chat) {
            tabLinkElementId = `/chat/${this.props.chat.chat_id}/stat/${selectedValue}`;
        } else if(this.props.userId) {
            tabLinkElementId = `/user/${this.props.userId}/chat/${selectedValue}`;
        }

        const tabLinkElement = document.getElementById(tabLinkElementId);
        if(tabLinkElement) {
            const tabElement = tabLinkElement.parentElement;

            if(tabElement) {
                tabElement.click();
            }
        }
    }
    

    render() {
        const tabs: JSX.Element[] = [];
        const tabPanels: JSX.Element[] = [];
        
        const selectDropdownOptions: SelectDropdownOption[] = [];


        let defaultTabIndex: number = this.props.defaultIndex;

        if(this.props.chat) {
            fieldsToPrint.forEach((stat, index) => {
                if(this.props.chat) {
                    let tabTitle = fieldsToPrintTabTitles[index];

                    const chatLink = `/chat/${this.props.chat.chat_id}/stat/${stat}`;

                    let routeToChatLink = () => {
                        let linkElement = document.getElementById(chatLink);
                        if(linkElement) {
                            linkElement.click();
                        }
                    }

                    tabs.push(
                        <Tab key={stat + "tab"} onClick={routeToChatLink}>
                            <Link id={chatLink} to={chatLink}>{tabTitle}</Link>
                        </Tab>
                    );

                    tabPanels.push(<TabPanel key={stat + "tabPanel"} />);


                    selectDropdownOptions.push({
                        value: stat,
                        text: tabTitle
                    });
                }
            });
        } else if(this.props.userId) {
            let i = 0;

            chatIds.forEach((chatId, index) => {
                if(this.props.allChatsData) {
                    const chat = this.props.allChatsData.find(chat => chat.chat_id === chatId);

                    if(chat) {
                        const member = chat.members.find(member => member.user_id === this.props.userId);

                        if(member) {
                            let tabTitle = chatNames[index];
    
                            const memberLink = `/user/${this.props.userId}/chat/${chatId}`;
            
                            let routeToMemberLink = () => {
                                let linkElement = document.getElementById(memberLink);
                                if(linkElement) {
                                    linkElement.click();
                                }
                            }
                
                            tabs.push(
                                <Tab key={chatId + "tab"} onClick={routeToMemberLink}>
                                    <Link id={memberLink} to={memberLink}>{tabTitle}</Link>
                                </Tab>
                            );
            
                            tabPanels.push(<TabPanel key={chatId + "tabPanel"} />);


                            selectDropdownOptions.push({
                                value: chatId,
                                text: tabTitle
                            });


                            if(this.props.defaultIndex === index) {
                                defaultTabIndex = i;
                            }
                            i++;
                        }
                    }
                }
            });
        }


        return (
            <div id="tabsWrapper">
                <Tabs defaultIndex={defaultTabIndex} className="desktop">
                    <TabList>
                        {tabs}
                    </TabList>

                    {tabPanels}
                </Tabs>

                <select id="selectDropdown" onChange={this.handleDropdownChange}
                    value={this.props.chat ? fieldsToPrint[this.props.defaultIndex] : chatIds[this.props.defaultIndex]}>
                    {
                        selectDropdownOptions.map(({ value, text }) => {
                            return <option value={value} key={value}>{text}</option>;
                        })
                    }
                </select>
            </div>
        );
    }
}