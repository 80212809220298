import React from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route
} from "react-router-dom";

import './App.css';

import SocketService from './socketService';

import { fetchDatabase } from './databaseAccessor';
import { DatabaseStore } from './databaseStore';

import Chat from './pages/chat/Chat';
import User from './pages/user/User';
import Home from './pages/home/Home';

import Footer from './components/footer/Footer';
import ErrorPage from './components/errorPage/ErrorPage';

import { ServerResponse } from './models/ServerResponse';
import { SocketEmit } from './models/SocketEmit';


type AppState = ServerResponse;

export default class App extends React.Component<{}, AppState> {
    private socketService = new SocketService();

    componentDidMount() {
        this.socketService.init();

        const onDatabaseRefreshedObservable = this.socketService.onDatabaseRefreshed();
        onDatabaseRefreshedObservable.subscribe((socketEmit: SocketEmit) => {
            this.fetchDatabaseInApp();
            console.log(socketEmit);
        });
    }

    componentWillUnmount() {
        this.socketService.disconnect();
    }

    async fetchDatabaseInApp() {
        const response = await fetchDatabase();
        console.log(response);
        if(response) {
            DatabaseStore.update(new DatabaseStore(response.allChatsData, response.allRuntimeStats));
        }
    }


    render() {
        return (
            <Router>
                <div className="App">
                    {/* <Header /> */}

                    <Switch>
                        <Route path="/chat/:chatId/stat/:stat?/:period?" component={Chat} />
                        <Route path="/chat/:chatId/:period?" component={Chat} />
                        <Route path="/user/:userId/chat/:chatId/:period?" component={User} />
                        <Route path="/user/:userId/:period?" component={User} />
                        <Route exact path="/" component={Home} />
                        <Route path="/">
                            <ErrorPage text={"Invalid Route"} />
                        </Route>
                    </Switch>
    
                    <Footer />
                </div>
            </Router>
        );
    }
}