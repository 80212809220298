import React from 'react';
import './ErrorPage.css';


type ErrorPageProps = {
    text: string
}

export default class ErrorPage extends React.Component<ErrorPageProps, {}> {
    render() {
        return (
            <div id="errorPage">
                <h1>Error: {this.props.text}</h1>
            </div>
        );
    }
}