import React from 'react';
import './ProgressBar.css';


type ProgressBarProps = {
    width: number
}

export default class ProgressBar extends React.Component<ProgressBarProps, {}> {
    render() {
        return (
            <div id="progressBar" style={{width:`${this.props.width}%`}} />
        );
    }
}