import React from 'react';
import './OverallStatistic.css';
import { OverallStatisticType } from '../../models/OverallStatisticType';


type OverallStatisticProps = {
    statistic: OverallStatisticType;
}

export default class OverallStatistic extends React.Component<OverallStatisticProps, {}> {
    render() {
        return (
            <div className="overallStatistic">
                <h1>{this.props.statistic.title}</h1>
                <p>{this.props.statistic.num}</p>
            </div>
        );
    }
}