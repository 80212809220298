import React from 'react';
import './ChatTileWrapper.css';
import ChatTile from '../chatTile/ChatTile';
import { ChatWithMemberArray } from '../../models/ChatSchema';
import { chatIds } from '../../global';


type ChatTileWrapperProps = {
    allChatsData: ChatWithMemberArray[]
}

export default class ChatTileWrapper extends React.Component<ChatTileWrapperProps, {}> {
    render() {
        return (
            <div id="chatTileWrapper">
                <div id="chatTileWrapperInner">
                    {
                        this.props.allChatsData.sort(function(a, b) {
                            return chatIds.indexOf(a.chat_id) - chatIds.indexOf(b.chat_id);
                        }).map((chat: ChatWithMemberArray) => {
                            return <ChatTile chat={chat} key={chat.chat_id} />;
                        })
                    }
                </div>
            </div>
        );
    }
}