import React from 'react';
import './UserInfo.css';

import { MemberWithFavoritesDict } from '../../models/MemberSchema';


type UserInfoProps = {
    member: MemberWithFavoritesDict
}

export default class UserInfo extends React.Component<UserInfoProps, {}> {
    render() {
        return (
            <div id="userInfoWrapper">
                <div id="userInfoWrapperInner">
                    <div className="userPicture" style={{backgroundImage: `url(${this.props.member.image_url})`}} />
                    <div className="userName">
                        <h1>{this.props.member.chat_id === "all" ? this.props.member.name : this.props.member.nickname}</h1>
                    </div>
                </div>
            </div>
        );
    }
}