import React from 'react';
import './MemberTile.css';
import { MemberWithFavoritesDict } from '../../models/MemberSchema';
import { Link } from 'react-router-dom';
import { fieldsToPrintMemberType } from '../../global';
import { roundNumber } from '../../utils';


type MemberTileProps = {
    member: MemberWithFavoritesDict,
    stat: fieldsToPrintMemberType,
    chatId: string,
    index: number
}

export default class MemberTile extends React.Component<MemberTileProps, {}> {
    render() {
        const memberLink = `/user/${this.props.member.user_id}/chat/${this.props.chatId}`;

        let memberImage = this.props.member.image_url;
        if(!memberImage) {
            memberImage = "https://i.groupme.com/300x300.png.6485c42fdeaa45b5a4b986b9cb1c91a2.preview";
        }


        return (
            <div className="memberTile">
                <Link to={memberLink} style={{margin: "0"}}>
                    <div className="memberImage" style={{backgroundImage: `url(${memberImage})`}}></div>
                </Link>

                <Link to={memberLink}>#{this.props.index + 1} {this.props.chatId === "all"
                    ? this.props.member.name : this.props.member.nickname}</Link>

                <p>{roundNumber(this.props.member[this.props.stat])}</p>
            </div>
        );
    }
}