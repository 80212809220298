import React from 'react';

import './User.css';
import OverallStatisticsWrapper from '../../components/overallStatisticWrapper/OverallStatisticWrapper';
import RankingsWrapper from '../../components/rankingsWrapper/RankingsWrapper';
import UserInfo from '../../components/userInfo/UserInfo';
import { ChatWithMemberArray } from '../../models/ChatSchema';
import Header from '../../components/header/Header';
import { OverallStatisticType } from '../../models/OverallStatisticType';
import { roundNumber } from '../../utils';
import { RuntimeStats } from '../../models/RuntimeStats';
import { DatabaseStore } from '../../databaseStore';
import { timePeriodsType, chatIds, chatIdsType } from '../../global';
import LoadingScreen from '../../components/loadingScreen/LoadingScreen';
import { MemberWithFavoritesDict } from '../../models/MemberSchema';
import ErrorPage from '../../components/errorPage/ErrorPage';
import { ServerResponse } from '../../models/ServerResponse';
import { Ranking } from '../../models/Ranking';
import TabsWrapper from '../../components/tabsWrapper/TabsWrapper';


type UserProps = {
    match: {
        params: {
            userId: string,
            chatId: chatIdsType,
            period: timePeriodsType
        }
    }
}
type UserState = ServerResponse & {
    loaded: boolean
}

export default class User extends React.Component<UserProps, UserState> {
    constructor(props: UserProps) {
        super(props);

        this.state = {
            allChatsData: [],
            allRuntimeStats: [],
            loaded: false
        }
    }

    componentDidMount() {
        this.getDatabaseStore();
    }

    componentWillUnmount() {
        DatabaseStore.unsubscribe("user");
    }

    async getDatabaseStore() {
        this.setStateWithStore(await DatabaseStore.getStore());
        
        DatabaseStore.subscribe({
            id: "user",
            callback: (store) => this.setStateWithStore(store)
        });
    }

    setStateWithStore(store: DatabaseStore) {
        const { allChatsData, allRuntimeStats } = store;
        this.setState({ allChatsData, allRuntimeStats, loaded: true });
    }


    returnLoadingScreenOrError(errorText: string) {
        if(this.state.allChatsData.length === 0) {
            return (
                <div>
                    <Header title={"User Statistics for . . . "} allRuntimeStats={this.state.allRuntimeStats} />

                    <LoadingScreen />
                </div>
            );
        } else {
            return <ErrorPage text={errorText} />
        }
    }


    render() {
        let { userId, chatId, period } = this.props.match.params;
        if(!chatId) {
            chatId = "all";
        }

        const chat = this.state.allChatsData.find(chat => chat.chat_id === chatId);
        if(!chat) {
            return this.returnLoadingScreenOrError("Chat Data Not Found");
        }

        const member = chat.members.find(member => member.user_id === userId);
        if(!member) {
            return this.returnLoadingScreenOrError("Invalid User Id");
        }

        let statistics: OverallStatisticType[] = [
            {
                title: "Likes Recieved",
                num: member.numMessagesLikedByOthers
            }, {
                title: "Messages Sent",
                num: member.numSentMessages
            }, {
                title: "Likes Given",
                num: member.numLikedMessages
            }, {
                title: "Likes/Message",
                num: roundNumber(member.numMessagesLikedByOthers / member.numSentMessages)
            }, {
                title: "Self Likes",
                num: member.numSelfLikes
            }, {
                title: "Characters/Message",
                num: roundNumber(member.numCharactersSent / member.numSentMessages)
            }, {
                title: "Mentioned or Mentioning",
                num: member.numTimesMentioned + member.numTimesMentionedOthers
            }, {
                title: "Mentioned/Mentioning",
                num: roundNumber(member.numTimesMentioned / member.numTimesMentionedOthers)
            }
        ];

        const rankings = Object.keys(member.favorites).sort(function(a, b) {
            return member.favorites[b] - member.favorites[a];
        }).map(userId => {
            const otherUser = chat.members.find(user => user.user_id === userId)!;
            
            return {
                title: otherUser.name,
                value: member.favorites[userId],
                user_id: userId,
                numSentMessages: otherUser.numSentMessages
            }
        });

        const rankingsSortedByPercent: Ranking[] = JSON.parse(JSON.stringify(rankings)).sort(function(a: Ranking, b: Ranking) {
            if(a.numSentMessages && b.numSentMessages) {
                return (b.value / b.numSentMessages) - (a.value / a.numSentMessages);
            }
        });


        return (
            <div>
                <Header title={`User Statistics for ${member.name}`} allRuntimeStats={this.state.allRuntimeStats} />

                {
                    this.state.allChatsData.length === 0 ? <LoadingScreen /> : [
                        <UserInfo key="0" member={member} />,

                        <TabsWrapper key="1" defaultIndex={chatIds.indexOf(chatId)} userId={member.user_id}
                            allChatsData={this.state.allChatsData} />,
            
                        <OverallStatisticsWrapper key="2" statistics={statistics} />,
                            
                        <RankingsWrapper key="3" rankings={rankings} isUserRankings={true} chatId={chatId}
                            rankingsSortedByPercent={rankingsSortedByPercent} />
                    ]
                }
            </div>
        );
    }
}