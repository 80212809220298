import React from 'react';
import './LoadingScreen.css';


export default function LoadingScreen() {
    return (
        <div id="loadingScreen">
            <section className="wrapper">
                <div className="spinner">
                    <i></i>
                    <i></i>
                    <i></i>
                    <i></i>
                    <i></i>
                    <i></i>
                    <i></i>
                </div>
            </section>
        </div>
    );
}